module.exports = {

	decisionBlockScript: `
package main

import (
	"encoding/json"

	"github.com/zinscky/log"
)

// this can be defined by you based
// on your event structure
type Event struct {
	Sub       string
	Processed bool
}

// Run function parameters
//
//  1. config - key value pair configured in the transformation
//  2. event - your event data as json string. you need to manualy
//             unmarshal it into appropriate struct.
//  3. vars - these are global variable and can be accessed by all
//            transformations/destination in the given pipeline.
//  4. log - the thread safe logger. log.Info, log.Debug, log.Warn, log.Error. It has same signature as fmt.Printf()
//
// Returns
//
//  1. a boolean value to indicate which branch should be run next.
//	2. error if any

func Run(
	config map[string]string,
	event string,
	vars map[string]string,
	log *log.Logger,
) (bool, error) {
	myEvent := &Event{}
	err := json.Unmarshal([]byte(event), myEvent)
	if err != nil {
		return false, err
	}
	log.Info("incoming event %s", event)
	if myEvent.Sub == "" {
		return false, nil
	}
	return true, nil
}

	`,

    modScriptTemplate:
        `
module myplugin

// currently backend only supports go v1.22
go 1.22

require github.com/zinscky/log v1.0.1

`,
    mainScriptTemplate:
        `
package main

import (
	"encoding/json"
	"github.com/zinscky/log"
)

// this can be defined by you based
// on your event structure
type Event struct {
	Sub       string
	Processed bool
}

// Run function parameters
//
//  1. config - key value pair configured in the transformation
//  2. event - your event data as json string. you need to manualy 
//             unmarshal it into appropriate struct.
//  3. vars - these are global variable and can be accessed by all 
//            transformations/destination in the given pipeline.
//  4. log - the thread safe logger. log.Info, log.Debug, log.Warn, log.Error.
//
// Returns
//
//  1. the modified event. pipeline will fail if it is not returned.
//  2. vars - the global pariable passed in this fuction.
//  3. error

func Run(
	config map[string]string,
	event string,
	vars map[string]string,
	log *log.Logger,
) (string, map[string]string, error) {
	myEvent := &Event{}
	err := json.Unmarshal([]byte(event), myEvent)
	if err != nil {
		return event, vars, err
	}
	log.Info("incoming event %s", event)
	return event, vars, nil
}


`,
    mainScriptDestinationTemplate:
        `
package main

import (
	"github.com/zinscky/log"
)

func Setup(config map[string]string, log *log.Logger) error { /* optional */
        //setup connection
	return nil
}

func Teardown(config map[string]string, log *log.Logger) error {/* optional */
        //teardown connection
	return nil
}

func Execute(config map[string]string, event string, vars map[string]string, log *log.Logger) (map[string]string, error) {/* required */
        //send to destination.
	return vars, nil
}
`

}
